<nav id="navbar" class="navbar navbar-fixed-top">
  <!-- header -->
  <div class="navbar-header">
      <div class="brand-title">
        <div class="wyld-logo">
          <img class="logo" alt="Wyil Logo" src="/assets/images/header-logo.svg"/>
        </div>
      </div>
  </div>
</nav>
