import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-technical-difficulties',
  templateUrl: './technical-difficulties.component.html',
  styleUrls: ['./technical-difficulties.component.less']
})
export class TechnicalDifficultiesComponent {

}
