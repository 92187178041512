<body>
<div class="centerDiv">
  <div>
    <img src="/assets/icons/shut-down-icon.svg"/>
  </div>
  <div class="textBlock">
    <div class="firstBlockText">{{firstBlockText | translate}}</div>
    <div class="secondBlockText">{{secondBlockText | translate}}</div>
  </div>
</div>

</body>


