import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ScheduledMaintenanceComponent } from './scheduled-maintenance/scheduled-maintenance.component';
import { TechnicalDifficultiesComponent } from './technical-difficulties/technical-difficulties.component';
import { RegisterComponent } from './register/register.component';

const routes: Routes = [{
  path: '',
  component: ScheduledMaintenanceComponent,
  data: {
    title: 'schedule-maintenance.pageTitle'
  }
}, {
  path: 'technical-difficulties',
  component: TechnicalDifficultiesComponent,
  data: {
    title: 'technical-difficulties.pageTitle'
  }
}, {
  path: 'register',
  component: RegisterComponent,
  data: {
    title: 'register.pageTitle'
  }
}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
