import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-template',
  templateUrl: './page-template.component.html',
  styleUrls: ['./page-template.component.less']
})
export class PageTemplateComponent implements OnInit {
  @Input()
  firstBlockText: string;
  @Input()
  secondBlockText: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
