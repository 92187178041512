import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-scheduled-maintenance',
  templateUrl: './scheduled-maintenance.component.html',
  styleUrls: ['./scheduled-maintenance.component.less']
})
export class ScheduledMaintenanceComponent {

}
